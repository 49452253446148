module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"eextensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":690}},{"resolve":"gatsby-remark-normalize-paths","options":{"pathFields":["cover"]}},"gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":"50","maintainCase":false,"removeAccents":true}},"gatsby-remark-prismjs"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#C7A27C"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Siddharth Rawat","short_name":"sydrawat","description":"Siddharth Rawat is a skilled Backend Engineer specializing in Kubernetes, Site Reliability, DevOps, and Cloud technologies.","start_url":"/","background_color":"#EEE8E8","theme_color":"#C7A27C","display":"minimal-ui","icon":"static/logos/logo-1024.png","icons":[{"src":"static/logos/logo-512.png","sizes":"512x512","type":"image/png"},{"src":"static/logos/logo-1024.png","sizes":"1024x1024","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5e82421889e5bc92fbe0f7d85804895f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"none"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
